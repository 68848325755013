// src/components/Shop/ProductDetail/Services/Services.js

import React from 'react'

export default function Services() {
  return (
    <section className="cdx-services space-py-80">    
      <div className="custom-container">
        <div className="row services-row">
          <div className="col-xl-3 col-sm-6">
            <div className="service-grid">
              <div className="icon-wrap"><i className="icofont-anchor"></i></div>
              <h3>Personalized Pirate Flag</h3>
              <p className="text-light">Design and create your unique pirate flag with the IJRR. Show the world your pirate spirit by flying a flag that represents your identity and brand.</p>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-grid">
              <div className="icon-wrap"><i className="icofont-compass-alt"></i></div>
              <h3>Exclusive Membership</h3>
              <p className="text-light">Join the International Jolly Roger Registry to unlock exclusive access to events, festivals, and a pirate-loving community from all corners of the seven seas.</p>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-grid">
              <div className="icon-wrap"><i className="icofont-ship-wheel"></i></div>
              <h3>Custom Merchandise</h3>
              <p className="text-light">Put your pirate brand on all kinds of merchandise—from flags to stickers and more. Showcase your pirate pride with custom items available only to members.</p>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-grid">
              <div className="icon-wrap"><i className="icofont-skull-danger"></i></div>
              <h3>Pirate Festivals</h3>
              <p className="text-light">Get ready to celebrate your pirate identity at international pirate festivals. Meet like-minded buccaneers and share in the fun at exclusive IJRR-sponsored events.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
