// src/components/Shop/ProductDetail/TextTab/TextTab.js

import React, { useState, useEffect } from 'react';
import './TextTab.css'

export default function TextTab({ 
  isTextObjectSelected, 
  selectedTextObject, 
  sizeOptions = [], 
  onTextAdded, 
  onTextUpdate, 
  onFontChange, 
  onColorChange, 
  onRotationChange, 
  onOutlineChange, 
  onOutlineColorChange, 
  onSizeChange 
}) {
  const [userText, setUserText] = useState('');
  const [currentText, setCurrentText] = useState('');
  const [selectedSize, setSelectedSize] = useState(20);
  const [selectedOutlineColor, setSelectedOutlineColor] = useState('#B4B4B8');

  // Watcher: Sync selectedTextObject with currentText
  useEffect(() => {
    setCurrentText(selectedTextObject ? selectedTextObject.text : '');
  }, [selectedTextObject]);

  // Handler: Emit text update when currentText changes
  useEffect(() => {
    if (currentText) {
      onTextUpdate(currentText);
    }
  }, [currentText, onTextUpdate]);

  // Event handlers for text editor
  const handleTextAdd = () => {
    if (userText.trim()) {
      onTextAdded(userText);
      setUserText(''); // Clear the input field after adding text
    }
  };

  const handleFontChange = (e) => {
    onFontChange(e.target.value);
  };

  const handleColorChange = (e) => {
    onColorChange(e.target.value);
  };

  const handleRotationChange = (e) => {
    onRotationChange(parseInt(e.target.value, 10));
  };

  const handleOutlineChange = (e) => {
    onOutlineChange({
      width: parseFloat(e.target.value),
      color: selectedOutlineColor
    });
  };

  const handleOutlineColorChange = (e) => {
    setSelectedOutlineColor(e.target.value);
    onOutlineColorChange(e.target.value);
  };

  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);
    onSizeChange(e.target.value);
  };

  return (
    <div>
      {isTextObjectSelected ? (
        <div className="text-editor-container">
          <form>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter your text here"
                id="text-input"
                value={currentText}
                onChange={(e) => setCurrentText(e.target.value)}
              />
            </div>
            <div className="settings-container">
              <div className="font-settings settings-row">
                <label htmlFor="font-select">Font</label>
                <select id="font-select" className="form-select" onChange={handleFontChange}>
                  <option value="Trebuchet MS">Trebuchet MS</option>
                  <option value="Black-Pearl">Black Pearl</option>
                  <option value="Pirate-Treasure">Pirate Treasure</option>
                  <option value="Caribbean">Caribbean</option>
                  <option value="Jolly-Roger">Jolly Roger</option>
                  <option value="Skulls">Skulls and Crossbones</option>
                  <option value="Buccaneer">Baccaneer</option>
                  <option value="Sea-Dog">Sea Dog</option>
                  <option value="TimesNewRoman">Times New Roman</option>
                  <option value="Arial">Arial</option>
                  <option value="Courier New">Courier New</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Verdana">Verdana</option>
                </select>
              </div>
              <div className="color-settings settings-row">
                <label htmlFor="color-select">Text Color</label>
                <input type="color" onInput={handleColorChange} />
              </div>
              <div className="rotation-settings settings-row">
                <label htmlFor="rotation-range">Rotation</label>
                <input type="range" id="rotation-range" min="-180" max="180" onInput={handleRotationChange} />
              </div>
              <div className="outline-settings settings-row">
                <label htmlFor="outline-select" className="settings-label">Outline</label>
                <input type="color" id="outline-color-select" value={selectedOutlineColor} onInput={handleOutlineColorChange} />
                <select id="outline-select" className="form-select settings-control" onChange={handleOutlineChange}>
                  <option value="0">No Outline</option>
                  <option value="0.5">Very Thin</option>
                  <option value="1">Thin</option>
                  <option value="2">Light</option>
                  <option value="3">Medium</option>
                  <option value="4">Bold</option>
                  <option value="5">Thick</option>
                  <option value="6">Extra Thick</option>
                </select>
              </div>
              <div className="size-settings settings-row">
                <label htmlFor="size-select">Text Size</label>
                <select id="size-select" className="form-select" value={selectedSize} onChange={handleSizeChange}>
                  {sizeOptions.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="text-input-container">
          <h5 className="label-tab-container">Add personalized text here</h5>
          <input
            type="text"
            className="form-control text-input"
            placeholder="Enter text here"
            value={userText}
            onChange={(e) => setUserText(e.target.value)}
          />
          <button className="btn btn-primary text-button" onClick={handleTextAdd}>
            Add To Design
          </button>
        </div>
      )}
    </div>
  );
}
