// src/components/Shop/ProductDetail/CustomizeTab/CustomizeTab.js

import React, { useState, useEffect } from 'react';
import './CustomizeTab.css'

export default function CustomizeTab({ initialColors = [], initialSizes = {}, initialSelectedColorIndex = 0, onColorSelected, onSizeSelected, onQuantitySelected }) {
  // State variables
  const [selectedColors, setSelectedColors] = useState(initialColors);
  const [selectedSizes, setSelectedSizes] = useState(initialSizes || {});
  const [selectedSize, setSelectedSize] = useState(Object.keys(initialSizes)[0] || null); // Set the first size as default
  const [selectedColorIndex, setSelectedColorIndex] = useState(initialSelectedColorIndex || 0);
  const [selectedQuantity, setSelectedQuantity] = useState(1); // Default quantity
  const [availableQuantities, setAvailableQuantities] = useState([]); // Available quantities for the selected size

  // Update available quantities based on selected size
  useEffect(() => {
    updateAvailableQuantities();
  }, [selectedSize, selectedSizes]);

  // Handle color selection
  const selectColor = (index) => {
    setSelectedColorIndex(index);
    const colorInfo = selectedColors[index];
    
    if (colorInfo && colorInfo.sizes) {
      setSelectedSizes(colorInfo.sizes);
      const firstSize = Object.keys(colorInfo.sizes)[0];
      setSelectedSize(firstSize); // Set the first size as default
      setAvailableQuantities(getQuantities(firstSize)); // Update available quantities
      setSelectedQuantity(1); // Reset to default quantity

      // Emit selected events
      onColorSelected && onColorSelected(index); // Emit color-selected event
      onQuantitySelected && onQuantitySelected(1); // Emit quantity-selected event
    } else {
      console.error(`No sizes found for the selected color at index ${index}`);
    }
  };

  // Handle size selection
  const selectSize = (size) => {
    setSelectedSize(size);
    setAvailableQuantities(getQuantities(size)); // Update quantities
    setSelectedQuantity(1); // Reset to default quantity

    // Emit selected events
    onSizeSelected && onSizeSelected(size);
    onQuantitySelected && onQuantitySelected(1);
  };

  // Update available quantities based on size
  const getQuantities = (size) => {
    const maxQuantity = selectedSizes[size] || 0;
    return Array.from({ length: maxQuantity }, (_, i) => i + 1);
  };

  // Update quantities when the selected size changes
  const updateAvailableQuantities = () => {
    if (selectedSize && selectedSizes[selectedSize]) {
      const quantity = selectedSizes[selectedSize];
      const newQuantities = Array.from({ length: quantity }, (_, i) => i + 1);
      setAvailableQuantities(newQuantities);
      setSelectedQuantity(newQuantities[0] || 1); // Set the first quantity as default
      onQuantitySelected && onQuantitySelected(newQuantities[0] || 1); // Emit selected quantity
    } else {
      setAvailableQuantities([]);
      setSelectedQuantity(1);
    }
  };

  return (
    <div className="product-detailright">
      <div className="product-card">
        {/* Color selection */}
        <div className="detail-group">
          <h6 className="label-tab-container">Choose your perfect color</h6>
          <ul className="product-color">
            {selectedColors.map((colorInfo, index) => (
              <li
                key={colorInfo.color}
                className={selectedColorIndex === index ? 'active' : ''}
                onClick={() => selectColor(index)}
              >
                <div style={{ backgroundImage: `url(${colorInfo.thumbnail})` }}></div>
              </li>
            ))}
          </ul>
        </div>

        {/* Size selection */}
        <div className="detail-group">
          <h6 className="label-tab-container">Select the right size for you</h6>
          <ul className="product-size">
            {Object.keys(selectedSizes).map((size) => (
              <li
                key={size}
                className={selectedSize === size ? 'active' : ''}
                onClick={() => selectSize(size)}
              >
                <a href="#">{size}</a>
              </li>
            ))}
          </ul>
        </div>

        {/* Quantity selection */}
        <div className="detail-group">
          <h6 className="label-tab-container">Select quantity for your order</h6>
          <select value={selectedQuantity} onChange={(e) => setSelectedQuantity(Number(e.target.value))}>
            {availableQuantities.map((n) => (
              <option key={n} value={n}>
                {n}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
