// src/components/Home/Home.js

import React from 'react';
import HomeSlider from './HomeSlider/HomeSlider';

const Home = () => {
  return (
    <>
      <HomeSlider />
    </>
  );
};

export default Home;
