// src/components/About/About.js

import React from "react";
import "./About.css";

export default function About() {
  return (
    <div>
      {/* About Us Section */}
      <section className="about-us space-pb-80">
        <div className="container">
          <div className="row about-row">
            <div className="col-lg-5">
              <div className="img-wrap">
                <img
                  className="img-fluid"
                  src="../assets/images/ijrr/IJRR.png"
                  alt="about us"
                />

                <div className="contact-info">
                  <button className="btn btn-primary">GET IN TOUCH</button>

                  {/* Social Icons */}
                  <div className="social-icons">
                    <a
                      href="https://facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                    <a
                      href="https://twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                    <a
                      href="https://plus.google.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-google-plus"></i>
                    </a>
                    <a
                      href="https://linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                  </div>

                  {/* Contact Information */}
                  <div className="contact-info-phone">
                    <a className="phone" href="tel:1-800-1234-567">
                      <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                      1-800-1234-567
                    </a>
                    <a
                      className="email"
                      href="mailto:info@jollyrogerregistry.com"
                    >
                      <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                      info@jollyrogerregistry.com
                    </a>
                  </div>

                  {/* Motto, Creed, and Code */}
                  <div className="motto-creed-code">
                    <p>
                      <span className="highlight">Motto:</span> “My Jolly Roger”
                    </p>
                    <p>
                      <span className="highlight">Creed:</span> “Live and Die
                      Under It”
                    </p>
                    <p>
                      <span className="highlight">Code:</span> The Articles of
                      Agreement
                    </p>
                  </div>

                  {/* Commitments Link */}
                  <a href="#commitments" className="commitments-link">
                    Commitments of the Pyrate’s Code
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="aboutus-contain">
                <div>
                  <h2 className="original-title">
                    INTERNATIONAL JOLLY ROGER REGISTRY
                  </h2>
                  <br />
                  <h3>
                    Established June 1, 2018, Costa Rica, SA -{" "}
                    <a href="http://www.ijrregistry.org">ijrregistry.org</a>
                  </h3>
                  <br />
                  <p className="text-dark tradition-text">
                    The International Jolly Roger Registry SRL was established
                    in Costa Rica in 2018. The purpose of the International
                    Jolly Roger Registry is to journal or “Register” each
                    individuals “Personal” emblem or logo. This means, each
                    emblem or logo will be Internationally Registered to the
                    “Individuals” that register with the International Jolly
                    Roger Registry
                  </p>
                  <br />
                  <p className="text-dark tradition-text">
                    Each Flag will have its own individual design and will be
                    approved by the Jolly Roger Registry and assigned to the
                    Jolly Rogers assigned number. That will remain permanent in
                    the books and records of the Jolly Roger Registry.
                  </p>
                  <br />
                  <p className="text-dark tradition-text">
                    Once registered you have your own emblem for your flag .
                    Your emblem represents you the same way a family crest does.
                    The Jolly Roger registry will make your flag, designed by
                    you, and it will be your official flag certified from the
                    registry. All certified products and services will come
                    directly from the Jolly Roger Registry to be officially
                    considered “Jolly Roger Certified”. This is an important
                    issue that keeps everyone’s own personal identity and
                    certifications safeguarded.
                  </p>
                  <br />
                  <p className="text-dark tradition-text">
                    It is simple to get started and register at the Jolly Roger
                    Registry your own name and “emblem” flag. Go to the “Get
                    Started” button on the website www.ijrregistry.org to sign
                    up and develop your Jolly Roger Identity.
                  </p>
                  <br />
                  <h2 className="customH2">Let the Fun began!</h2>
                  <br />
                  <p className="text-dark tradition-text">
                    The time period during the “Gloden Age of Piracy” is the
                    period most recoganizable today. Over time in history, many
                    have romanticized the “Golden Age of Piracy” from this
                    unforgettable historical period. But in the end, once you
                    were a Pyrate you were on the other end of the Law.
                  </p>
                  <br />
                  <p className="text-dark tradition-text">
                    The “Jolly Roger” represents the identity of the Captian of
                    the ship and these individuals mostly styled flags
                    representing their motives. This is the basic concept of the
                    Jolly Roger flag. The International Jolly Roger Registry’s
                    intentions are to carry on the tradition of individual flag
                    representation.
                  </p>
                  <br />
                  <p className="text-dark tradition-text">
                    Most of the history of the Jolly Roger is based upon Pyrates
                    and their freedom on the high seas and away from government
                    and what they felt as certain opressions of life. This
                    concept is also a big part of the philosophy of the
                    International Jolly Roger Registry to incorporate each
                    individuals personality and identity into their Own Personal
                    “Jolly Roger”
                  </p>
                  <br />
                  <h2 className="customH2">This is how it all started.</h2>
                  <br />
                  <h3>
                    <i>
                      “Come experience the new Jolly Roger International
                      Family.”
                    </i>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
