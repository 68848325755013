// src/components/Shop/Shop.js

import React, { useState } from 'react';
import Breadcrumb from './Breadcrumb/Breadcrumb';
import Categories from './Categories/Categories';
import ProductDetail from './ProductDetail/ProductDetail';
import Services from './ProductDetail/Services/Services';

export default function Shop() {
  const [selectedImageUrl, setSelectedImageUrl] = useState(''); // Hold the selected image URL
  const [overlayImageUrl, setOverlayImageUrl] = useState(''); // Hold the selected overlay image URL

  // Handler to set the selected image from Categories
  const handleModelSelect = (imageUrl) => {
    console.log("Selected image URL from Categories:", imageUrl); // Log the image URL
    setSelectedImageUrl(imageUrl); // Update state with selected image URL
  };

  // Handler to set overlay image from ShutterstockSearch
  const handleOverlayImageSelect = (imageUrl) => {
    console.log("Selected overlay image URL:", imageUrl);
    setOverlayImageUrl(imageUrl); // Update state with the selected overlay image URL
  };

  return (
    <>
      {/* Pass handleModelSelect to Categories */}
      <Categories onModelSelect={handleModelSelect} />
      {/* Pass selectedImageUrl, setSelectedImageUrl, and handleOverlayImageSelect to ProductDetail */}
      <ProductDetail 
        selectedImageUrl={selectedImageUrl} 
        setSelectedImageUrl={setSelectedImageUrl} 
        overlayImageUrl={overlayImageUrl}
        onOverlayImageSelect={handleOverlayImageSelect}
      />
      <Services />
    </>
  );
}
