// src/components/Maps/Maps.js

import React from 'react';
import './Maps.css';

export default function Maps() {
  return (
    <section className="home-space">
      <div className="homearrowlide-1 arrow-style1">
        <div className="fashion-slider">
          <img className="img-fluid img-src" src="../assets/images/ijrr/map.jpg" alt="Maps" />
          <div className="custom-container">
            <div className="row">
              <div className="col-12">
                <div className="fashion-contain">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
