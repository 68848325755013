// src/components/Shop/ProductDetail/ShutterstockSearch/ShutterstockSearch.js

import React, { useState, useEffect, useRef } from "react";
import './ShutterstockSearch.css';

export default function ShutterstockSearch({ onImageSelected }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [images, setImages] = useState([]);
  const searchInputRef = useRef(null);

  useEffect(() => {
    loadDefaultImages();
  }, []);

  const loadDefaultImages = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/logos`
      ); // Adjust your API endpoint
      const data = await response.json();
      setImages(data.images);
    } catch (error) {
      console.error("Error loading default images:", error);
    }
  };

  const searchImages = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/logos?search=${searchTerm}`);
      const data = await response.json();
      setImages(data.images);
    } catch (error) {
      console.error("Error searching images:", error);
    }
  };

  const selectImage = (imageUrl) => {
    console.log("Image selected:", imageUrl); // Log the selected image
    onImageSelected(imageUrl); // Emit the event to the parent component to add the overlay
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      searchImages();
    }
  };

  return (
    <div>
      <h5 className="label-tab-container">
        Start your design by searching for logos or images
      </h5>

      {/* Search Form */}
      <div className="input-group mb-3">
        <input
          type="text"
          ref={searchInputRef}
          className="form-control"
          placeholder="Search"
          aria-label="Search"
          value={searchTerm}
          onChange={handleInputChange}
          onKeyUp={handleKeyUp}
        />
        <button
          className="btn btn-outline-secondary btn-search-editor"
          type="button"
          id="button-addon2"
          onClick={searchImages}
        >
          <i className="fa fa-search"></i>
        </button>
      </div>

      {/* Image Gallery List */}
      <div className="card-body editor-gallery">
        <ul className="blogpost-list filter-list cdx-scroll gallery-list">
          {images.map((image, index) => (
            <li
              key={image.id || index}
              onClick={() => selectImage(image.url)}
              className="gallery-item"
            >
              <div className="media image-container">
                <img
                  className="img-fluid image-style"
                  src={image.url}
                  alt={image.description}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
