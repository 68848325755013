import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import './ImageEditor.css';

const ImageEditor = forwardRef(({ selectedImageUrl, overlayImageUrl, onObjectSelected, onObjectDeselected }, ref) => {
  const { editor, onReady } = useFabricJSEditor();
  const baseImage = '/assets/images/defaults/t-2.png';  // Default image URL
  const snapThreshold = 10;  // Snapping threshold in pixels

  // Expose method to add text to canvas via ref
  useImperativeHandle(ref, () => ({
    addTextToCanvas(text) {
      if (editor && editor.canvas) {
        const canvasCenter = editor.canvas.getCenter();
        const textObject = new window.fabric.Text(text, {
          left: canvasCenter.left,
          top: canvasCenter.top,
          fontFamily: 'Arial',
          fill: '#fff',
          fontSize: 20,
          originX: 'center',
          originY: 'center',
        });
        editor.canvas.add(textObject).setActiveObject(textObject);
        editor.canvas.renderAll();
      }
    },
  }));

  // Initialize guidelines
  const initGuides = () => {
    if (editor && editor.canvas) {
      const canvas = editor.canvas;
      editor.verticalGuideLine = canvas.getWidth() / 2;
      editor.horizontalGuideLine = canvas.getHeight() / 2;
    }
  };

  // Handle object selected
  const handleObjectSelected = (e) => {
    const object = e.selected[0];  // e.selected contains the selected objects
    if (object && object.type === 'text') {
      onObjectSelected(object);  // Notify parent component
    }
  };

  // Handle object deselection
  const handleObjectDeselected = () => {
    onObjectDeselected();  // Notify parent component
  };

  // Handle object movement and snapping
  const handleObjectMoving = (e) => {
    const object = e.target;

    // Snap to vertical center
    if (Math.abs(object.left - editor.verticalGuideLine) < snapThreshold) {
      object.set({
        left: editor.verticalGuideLine,
        originX: 'center',
      });
      showVerticalGuideLine();  // Show vertical guideline while moving
    }

    // Snap to horizontal center
    if (Math.abs(object.top - editor.horizontalGuideLine) < snapThreshold) {
      object.set({
        top: editor.horizontalGuideLine,
        originY: 'center',
      });
      showHorizontalGuideLine();  // Show horizontal guideline while moving
    }

    editor.canvas.renderAll();
  };

  // Show the vertical guideline
  const showVerticalGuideLine = () => {
    if (editor.vLine) {
      editor.canvas.remove(editor.vLine);
    }

    editor.vLine = new window.fabric.Line([editor.verticalGuideLine, 0, editor.verticalGuideLine, editor.canvas.getHeight()], {
      stroke: '#808080',
      strokeWidth: 1,
      strokeDashArray: [4, 3],
      selectable: false,
      evented: false,
    });

    editor.canvas.add(editor.vLine);
    editor.canvas.renderAll();
  };

  // Show the horizontal guideline
  const showHorizontalGuideLine = () => {
    if (editor.hLine) {
      editor.canvas.remove(editor.hLine);
    }

    editor.hLine = new window.fabric.Line([0, editor.horizontalGuideLine, editor.canvas.getWidth(), editor.horizontalGuideLine], {
      stroke: '#808080',
      strokeWidth: 1,
      strokeDashArray: [4, 3],
      selectable: false,
      evented: false,
    });

    editor.canvas.add(editor.hLine);
    editor.canvas.renderAll();
  };

  // Hide guidelines after snapping
  const hideGuideLines = () => {
    if (editor.vLine) {
      editor.canvas.remove(editor.vLine);
      editor.vLine = null;
    }
    if (editor.hLine) {
      editor.canvas.remove(editor.hLine);
      editor.hLine = null;
    }
    editor.canvas.renderAll();
  };

  // Handle key press for deleting the active object
  const handleDeleteKey = (event) => {
    if (editor && editor.canvas) {
      if (event.key === 'Delete' || event.key === 'Supr' || (event.key === 'Backspace' && event.metaKey)) {
        const activeObject = editor.canvas.getActiveObject();
        if (activeObject) {
          editor.canvas.remove(activeObject);
          editor.canvas.renderAll();  // Re-render the canvas after deletion
        }
      }
    }
  };

  // Attach event listeners for canvas actions
  useEffect(() => {
    if (editor && editor.canvas) {
      initGuides();

      // Handle object moving, selection, and deselection
      editor.canvas.on('object:moving', handleObjectMoving);
      editor.canvas.on('selection:created', handleObjectSelected);
      editor.canvas.on('selection:updated', handleObjectSelected);  // For re-selecting objects
      editor.canvas.on('selection:cleared', handleObjectDeselected);

      // Hide guidelines when object movement ends
      editor.canvas.on('mouse:up', hideGuideLines);

      // Attach keydown listener for delete functionality
      document.addEventListener('keydown', handleDeleteKey);

      // Cleanup listeners when component unmounts
      return () => {
        editor.canvas.off('object:moving', handleObjectMoving);
        editor.canvas.off('selection:created', handleObjectSelected);
        editor.canvas.off('selection:updated', handleObjectSelected);
        editor.canvas.off('selection:cleared', handleObjectDeselected);
        editor.canvas.off('mouse:up', hideGuideLines);
        document.removeEventListener('keydown', handleDeleteKey);
        hideGuideLines();  // Ensure to remove guidelines if any are displayed
      };
    }
  }, [editor]);

  // Load and center the background image
  const loadAndCenterImage = (imageUrl) => {
    const img = new window.Image();
    img.src = imageUrl;

    img.onload = () => {
      if (editor && editor.canvas) {
        const canvasWidth = editor.canvas.getWidth();
        const canvasHeight = editor.canvas.getHeight();
        const imageWidth = img.width;
        const imageHeight = img.height;

        const scaleX = canvasWidth / imageWidth;
        const scaleY = canvasHeight / imageHeight;
        const scaleToFit = Math.min(scaleX, scaleY);

        editor.canvas.setBackgroundImage(
          imageUrl,
          editor.canvas.renderAll.bind(editor.canvas),
          {
            originX: 'center',
            originY: 'center',
            top: canvasHeight / 2,
            left: canvasWidth / 2,
            scaleX: scaleToFit,
            scaleY: scaleToFit,
          }
        );
      }
    };

    img.onerror = () => {
      console.error('Failed to load background image:', imageUrl);
    };
  };

  // Add overlay image to the canvas
  const addOverlayImage = (imageUrl) => {
    const img = new window.Image();
    img.src = imageUrl;

    img.onload = () => {
      if (editor && editor.canvas) {
        const maxImgSize = 150;
        const scaleX = maxImgSize / img.width;
        const scaleY = maxImgSize / img.height;
        const scaleToFit = Math.min(scaleX, scaleY);

        const canvasCenter = editor.canvas.getCenter();

        const overlayImage = new window.fabric.Image(img, {
          left: canvasCenter.left,
          top: canvasCenter.top,
          scaleX: scaleToFit,
          scaleY: scaleToFit,
          originX: 'center',
          originY: 'center',
          cornerSize: 10,
          hasRotatingPoint: true,
          rotatingPointOffset: 40,
          borderColor: '#c5900b',
          cornerColor: '#8a2909',
          transparentCorners: false,
        });

        editor.canvas.add(overlayImage).setActiveObject(overlayImage);
        editor.canvas.renderAll();
      }
    };

    img.onerror = () => {
      console.error('Failed to load overlay image:', imageUrl);
    };
  };

  // Load background image and overlay when URLs change
  useEffect(() => {
    if (editor && editor.canvas) {
      const parentDiv = document.querySelector(".editor-wrap");
      const canvasWidth = parentDiv.clientWidth;
      const canvasHeight = parentDiv.clientHeight;

      editor.canvas.setDimensions({
        width: canvasWidth,
        height: canvasHeight
      });

      const imageUrlToLoad = selectedImageUrl || baseImage;
      loadAndCenterImage(imageUrlToLoad);
    }
  }, [editor, selectedImageUrl]);

  useEffect(() => {
    if (editor && overlayImageUrl) {
      addOverlayImage(overlayImageUrl);
    }
  }, [editor, overlayImageUrl]);

  return (
    <FabricJSCanvas className="imageEditorCanvas" onReady={onReady} />
  );
});

export default ImageEditor;
