// /src/components/Tradition/Tradition.js

import React from "react";
import './Tradition.css'

export default function Tradition() {
  return (
    <div>
      {/* Tradition  Section */}
      <section className="about-us space-pb-80">
        <div className="container">
          <div className="row about-row">
            <div className="col-lg-5">
            <div className="img-wrap">
                <img
                  className="img-fluid"
                  src="../assets/images/ijrr/IJRR.png"
                  alt="about us"
                />

                <div className="contact-info">
                  <button className="btn btn-primary">GET IN TOUCH</button>

                  {/* Social Icons */}
                  <div className="social-icons">
                    <a
                      href="https://facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                    <a
                      href="https://twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                    <a
                      href="https://plus.google.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-google-plus"></i>
                    </a>
                    <a
                      href="https://linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                  </div>

                  {/* Contact Information */}
                  <div className="contact-info-phone">
                    <a className="phone" href="tel:1-800-1234-567">
                      <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                      1-800-1234-567
                    </a>
                    <a
                      className="email"
                      href="mailto:info@jollyrogerregistry.com"
                    >
                      <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                      info@jollyrogerregistry.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="aboutus-contain">
                <div>
                  <h2 className="original-title">Pyrate History:</h2>
                  <br />
                  <h3>
                    “Golden Age of Piracy” dated 1660-1726.
                  </h3>
                  <br />
                  <br />
                  <h2 className="original-title-left">Privateer:</h2>
                  <br />
                  <p className="text-dark tradition-text">
                    A Privateer was the Captain and his ship that were
                    authorized by a particular Country to attack other Countries
                    ships and take the “booty” and split it with the Privateer
                    and Country supporting the Privateer.
                  </p>
                  <br />
                  <h2 className="original-title-left">Pyrate Definition:</h2>
                  <br />
                  <p className="text-dark tradition-text">
                    Pyrate or commonly known today as “Pirates” comes form the
                    term Piracy meaning robbery or illegal violance at sea.
                    Pyrates are individuals that choose to take to the High Seas
                    and to commander whatever is available with or without
                    violance. Most of the time, there we several groups of ships
                    that committed Piracy as a group.
                  </p>
                  <br />
                  <h3>
                    <i>
                      "Come experience the new Jolly Roger International
                      Family."
                    </i>
                  </h3>
                  <p className="text-dark tradition-text"></p>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="aboutus-contain">
                <div>
                  <h2 className="original-title">Jolly Roger Past, Present and Future</h2>
                  <br />
                  <p className="text-dark tradition-text">
                    The Jolly Roger has been sailing free for over several
                    centuries now. Flying your Jolly Roger, getting your own
                    personal Jolly Roger certified, it’s all about the “Freedom”
                    of your spirit in owning your own Jolly Roger.
                  </p>
                  <br />
                  <p className="text-dark tradition-text">
                    A skull and crossbones is a symbol consisting of a human
                    skull and two long bones crossed together under the skull.
                    The design originates in the Late Middle Ages as a symbol of
                    death and especially as a memento on tombstones.
                  </p>
                  <br />
                  <p className="text-dark tradition-text">
                    A pirate or “Jack” as otherwise known as, flew the Jolly
                    Roger during the Later Middle Ages (into the 1400s), the use
                    of the skull and crossbones began to diminish until it was
                    almost non-existent. The symbol was essentially unused
                    during the following century, until it was adopted by some
                    of the most feared people in the world – pirates.
                    Originally, pirates used simply a red flag on the top of
                    their ships’ mast. The red symbolized bloodshed, and the
                    fact that the pirates gave no quarter (show no mercy).
                    However, many pirates soon changed their flags from red to
                    black, and began to weave the skull and crossbones into
                    them. The Jolly Roger, as the flag came to be called,
                    symbolized death to its purveyors in its color and in its
                    symbol. "The skull-and-crossbones emblem [was] adopted by
                    pirates as a sinister warning of their evil intentions. The
                    emblem filled sailors with dread and signaled death to all
                    who saw it."
                  </p>
                  <br />
                  <p className="text-dark"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* Flags Section */}
      <section className="space-pb-80">
      <h2 className="original-title">BLACK FLAG</h2>
        <div className="custom-container">
          <p className="cdxtitle">
            Some of the famous pirate flags used from 1693 to 1724 are shown below:
          </p>
          <div className="row">
            <div className="col-sm-6 col-md-5 col-lg-3">
              <div className="product product-grid">
                <div className="product-image">
                  <img
                    className="img-fluid product-image-area"
                    src="../assets/images/ijrr/flag-grid-1.jpg"
                    alt="Captain Emanuel Wynne's Flag"
                  />
                </div>
                <h5 className="product-title offset-top-20">
                  Used by Captain Emanuel Wynne.
                </h5>
              </div>
            </div>

            <div className="col-sm-6 col-md-5 col-lg-3">
              <div className="product product-grid">
                <div className="product-image">
                  <img
                    className="img-fluid product-image-area"
                    src="../assets/images/ijrr/flag-grid-2.jpg"
                    alt="Blackbeard's Jolly Roger"
                  />
                </div>
                <h5 className="product-title offset-top-20">
                  Captain Edward Teach aka Blackbeard's Jolly Roger.
                </h5>
              </div>
            </div>

            <div className="col-sm-6 col-md-5 col-lg-3">
              <div className="product product-grid">
                <div className="product-image">
                  <img
                    className="img-fluid product-image-area"
                    src="../assets/images/ijrr/flag-grid-3.jpg"
                    alt="Jolly Roger of Captains Jean Thomas Dulaien and Walter Kennedy"
                  />
                </div>
                <h5 className="product-title offset-top-20">
                  The Jolly Roger of Captains Jean Thomas Dulaien and later, Walter Kennedy.
                </h5>
              </div>
            </div>

            <div className="col-sm-6 col-md-5 col-lg-3">
              <div className="product product-grid">
                <div className="product-image">
                  <img
                    className="img-fluid product-image-area"
                    src="../assets/images/ijrr/flag-grid-4.jpg"
                    alt="Bartholomew Roberts' Jolly Roger"
                  />
                </div>
                <h5 className="product-title offset-top-20">
                  Captain Bartholomew Roberts' Jolly Roger showing him and a skeleton holding an hourglass.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
