// src/components/Register/Register.js

import React from 'react';
import './Register.css'

export default function Register() {
  return (
    <section className="space-pb-80 product-detail-page">
      <div className="custom-container">

        {/* Registration Form */}
        <div className="codex-authmain">
          <form className="codex-authbox">
            <div className="group-form text-center">
              <h3>Create your account</h3>
              <h6>
                Already have an account?{' '}
                <a className="text-primary text-decoration-underline" href="login.html">
                  login in here
                </a>
              </h6>
            </div>
            
            <div className="group-form">
              <div className="group-small">
                <div>
                  <label className="form-label">First name</label>
                  <input className="form-control" type="text" placeholder="Edward" />
                </div>
                <div>
                  <label className="form-label">Last name</label>
                  <input className="form-control" type="text" placeholder="Teach" />
                </div>
              </div>
            </div>
            
            <div className="group-form">
              <div className="group-small">
                <div>
                  <label className="form-label">Mobile</label>
                  <input className="form-control" type="number" placeholder="+1-555-JOLLY-R" />
                </div>
                <div>
                  <label className="form-label">Email</label>
                  <input className="form-control" type="text" placeholder="mail@jollyroger.us.com" />
                </div>
              </div>
            </div>
            
            <div className="group-form">
              <div className="group-small">
                <div>
                  <label className="form-label">Password</label>
                  <input className="form-control" type="password" placeholder="********" />
                </div>
                <div>
                  <label className="form-label">Confirm Password</label>
                  <input className="form-control" type="password" placeholder="********" />
                </div>
              </div>
            </div>
            
            <div className="group-form">
              <div className="auth-remember">
                <span className="custom-check-input">
                  <input className="custom-input" type="checkbox" id="i-agree" name="i-agree" />
                  <label className="custom-input-label" htmlFor="i-agree"></label>
                </span>
                <label htmlFor="i-agree">I Agree Terms and conditions</label>
              </div>
            </div>
            
            <div className="group-form">
              <a className="btn d-block btn-primary" href="javascript:void(0)">Sign up</a>
            </div>
            
            <div className="group-form mb-0">
              <h5 className="auth-with">or register in with</h5>
              <ul className="cdxsocial-link">
                <li><a className="bg-fb" href="javascript:void(0);"><i className="fa-brands fa-facebook" aria-hidden="true"></i></a></li>
                <li><a className="bg-twt" href="javascript:void(0);"><i className="fa-brands fa-google" aria-hidden="true"></i></a></li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
