// src/components/Shop/ProductDetail/ProductDetail.js

import React, { useState, useEffect, useCallback, useRef } from "react";
import ShutterstockSearch from "./ShutterstockSearch/ShutterstockSearch";
import ImageEditor from "./ImageEditor/ImageEditor";
import TextTab from "./TextTab/TextTab";  
import UploadTab from "./UploadTab/UploadTab";
import CustomizeTab from "./CustomizeTab/CustomizeTab";
import './ProductDetail.css';

export default function ProductDetail({ selectedImageUrl, overlayImageUrl, setSelectedImageUrl, onOverlayImageSelect }) {  
  const [activeTab, setActiveTab] = useState("tab1");  // Manage which tab is currently active
  const [selectedModel, setSelectedModel] = useState(null);  // Store the current model details
  const [selectedColors, setSelectedColors] = useState([]);  // Store available colors for the selected model
  const [selectedSizes, setSelectedSizes] = useState({});    // Store available sizes for the selected model
  const [selectedText, setSelectedText] = useState(null);    // Store the selected text object, if any
  const [isTextObjectSelected, setIsTextObjectSelected] = useState(false);  // Determine if a text object is selected
  const [selectedQuantity, setSelectedQuantity] = useState(1);  // Store the quantity selected by the user
  const [user, setUser] = useState(null);  // Store user details, if logged in
  const [currentSelectedColorIndex, setCurrentSelectedColorIndex] = useState(0); // Track the selected color index

  const imageEditorRef = useRef(null);  // Use a ref to interact with ImageEditor

  // Fetch initial product details on mount
  useEffect(() => {
    initializeDefaultProduct();
  }, []);

  // Fetch product data and set default model
  const initializeDefaultProduct = useCallback(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/public-inventory`)
      .then((response) => response.json())
      .then((data) => {
        const categories = data.categories;
        if (categories.length > 0 && categories[0].models.length > 0) {
          const firstModel = categories[0].models[0];  // Select the first model in the first category as default
          updateProductModel(firstModel);
        } else {
          // Set a default model if no data is found
          setSelectedModel({
            model_id: null,
            name: "Default Product Name",
            price: "0.00",
            image: "../assets/images/defaults/t-2.png",
          });
        }
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  // Update the product model and set corresponding options (like color, size)
  const updateProductModel = (model) => {
    setSelectedModel({
      model_id: model.model_id,
      name: model.name,
      price: model.price,
      image: model.image,
    });
    
    const newColors = model.inventories.map((inventory) => ({
      color: inventory.color,
      thumbnail: inventory.thumbnail,
      sizes: inventory.sizes,
    }));
    setSelectedColors(newColors);
    setSelectedSizes(newColors.length > 0 ? { ...newColors[0].sizes } : {});
    
    // Update the image in the ImageEditor (set as background)
    setSelectedImageUrl(model.image);  // Make sure this is passed correctly from Shop.js
  };

  // Handle color selection
  const handleColorSelected = (colorIndex) => {
    const selectedColorInfo = selectedColors[colorIndex];
    if (selectedColorInfo) {
      // Set the selected color's image as the new background in the ImageEditor
      setSelectedImageUrl(selectedColorInfo.thumbnail);
      setCurrentSelectedColorIndex(colorIndex); // Keep track of the current selected color
    }
  };

  // Handle tab switching
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    if (tabId !== "tab2") {
      setIsTextObjectSelected(false);  // Clear the selected text object when switching away from the text tab
    }
  };

  // Handle object selection in the ImageEditor (e.g., when a text or image is selected)
  const handleObjectSelected = (object) => {
    if (object.type === 'text') {
      setIsTextObjectSelected(true);  // Set flag if the selected object is text
    } else {
      setIsTextObjectSelected(false);
    }
  };

  // Handle object deselection in the ImageEditor
  const handleObjectDeselected = () => {
    setIsTextObjectSelected(false);
  };

  // Handle text addition from TextTab
  const handleTextAdded = (text) => {
    if (imageEditorRef.current) {
      imageEditorRef.current.addTextToCanvas(text);
    }
  };

  // Handle adding product to the cart
  const addToCart = () => {
    if (!user) {
      console.log("no user");
      // Trigger a signup modal here if user is not logged in
    } else {
      console.log("User exists");
      // Handle the logic for adding the product to the cart
    }
  };

  // Define handleImageUpload function to process uploaded image
  const handleImageUpload = (uploadedImage) => {
    // Set the uploaded image as the overlay image in the ImageEditor
    onOverlayImageSelect(uploadedImage);
  };

  return (
    <section className="product-detail-page">
      <div className="custom-container">
        <div className="row">
          {/* Sidebar */}
          <div className="col-md-4 cdx-xl-55 product-options">
            <div className="product-card">
              <div className="row product-detailright">
                <div className="detail-group">
                  <div className="media">
                    <div>
                      {/* Display selected model name and price */}
                      <h2>{selectedModel?.name || "Product Name"}</h2>
                      <h6 className="text-light">Special price</h6>
                      <ul className="product-price">
                        <li className="new-price">
                          ${selectedModel?.price || "0.00"}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* Vertical Tabs */}
              <div className="row">
                <div className="col-md-2 cdx-xl-55 tabs-wrapper">
                  <div className="vertical-tabs-container">
                    <ul className="nav flex-column vertical-nav-tabs">
                      {/* Tab buttons */}
                      <li>
                        <button
                          className={`nav-link ${activeTab === "tab1" ? "active" : ""}`}
                          onClick={() => handleTabClick("tab1")}
                        >
                          <i className="fa fa-search"></i>
                          <span className="tab-title">Search</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className={`nav-link ${activeTab === "tab2" ? "active" : ""}`}
                          onClick={() => handleTabClick("tab2")}
                        >
                          <i className="fa fa-font"></i>
                          <span className="tab-title">Text</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className={`nav-link ${activeTab === "tab3" ? "active" : ""}`}
                          onClick={() => handleTabClick("tab3")}
                        >
                          <i className="fa fa-upload"></i>
                          <span className="tab-title">Upload</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className={`nav-link ${activeTab === "tab4" ? "active" : ""}`}
                          onClick={() => handleTabClick("tab4")}
                        >
                          <i className="fa fa-tshirt"></i>
                          <span className="tab-title">Customize</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Tab Content */}
                <div className="col-md-10">
                  <div className="tab-content vertical-tab-content">
                    {/* Tab 1: Search */}
                    {activeTab === "tab1" && (
                      <div className="tab-pane fade show active">
                        <ShutterstockSearch
                          onImageSelected={onOverlayImageSelect}  // Pass the handler for overlay selection
                        />
                      </div>
                    )}
                    {/* Tab 2: Text */}
                    {activeTab === "tab2" && (
                      <div className="tab-pane fade show active">
                        <TextTab
                          isTextObjectSelected={isTextObjectSelected}
                          selectedTextObject={selectedText}
                          sizeOptions={[10, 20, 30, 40, 50]}  // Example size options
                          onTextAdded={handleTextAdded}
                          onTextUpdate={(text) => console.log('Text Updated:', text)}
                          onFontChange={(font) => console.log('Font Changed:', font)}
                          onColorChange={(color) => console.log('Color Changed:', color)}
                          onRotationChange={(rotation) => console.log('Rotation Changed:', rotation)}
                          onOutlineChange={(outline) => console.log('Outline Changed:', outline)}
                          onOutlineColorChange={(color) => console.log('Outline Color Changed:', color)}
                          onSizeChange={(size) => console.log('Size Changed:', size)}
                        />
                      </div>
                    )}
                    {/* Tab 3: Upload */}
                    {activeTab === "tab3" && (
                      <div className="tab-pane fade show active">
                        <UploadTab onImageUploaded={handleImageUpload} /> {/* UploadTab implementation */}
                      </div>
                    )}
                    {/* Tab 4: Customize */}
                    {activeTab === "tab4" && (
                      <div className="tab-pane fade show active">
                        <CustomizeTab
                          initialColors={selectedColors}
                          initialSizes={selectedSizes}
                          initialSelectedColorIndex={currentSelectedColorIndex}
                          onColorSelected={handleColorSelected} // Handle color selection
                          onSizeSelected={(size) => console.log("Selected Size:", size)}
                          onQuantitySelected={(quantity) => console.log("Selected Quantity:", quantity)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Image Editor */}
          <div className="col-md-8 cdx-xl-45 image-editor-container">
            <div className="product-card">
              <div className="editor-wrap">
                {/* Pass selected image and overlay to ImageEditor */}
                <ImageEditor
                  ref={imageEditorRef}
                  selectedImageUrl={selectedImageUrl}  
                  overlayImageUrl={overlayImageUrl}  // New prop for overlay images
                  onObjectSelected={handleObjectSelected}  // Handle object selection
                  onObjectDeselected={handleObjectDeselected}  // Handle object deselection
                  onTextAdded={handleTextAdded}  // Handle text addition
                />
                {/* Add to Cart Button */}
                <div className="detail-group editor-actions">
                  <button className="btn btn-primary" onClick={addToCart}>
                    Add to cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
