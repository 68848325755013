// src/layout/Footer/Footer.js

import React from 'react';
import './Footer.css';

export default function Footer() {
  return (
    <footer className="cdx-footer minimal-footer">
      <div className="footer-content">
        <img src="../assets/images/ijrr/logo-light.png" alt="Jolly Roger Registry" className="footer-logo" />
        
        <p>© 2024 . <a href="/privacy-policy">Privacy Policy</a></p>
      </div>
    </footer>
  );
}
