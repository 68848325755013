// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './layout/Header/Header';
import Footer from './layout/Footer/Footer';
import Home from './components/Home/Home';
import About from './components/About/About';
import Tradition from './components/Tradition/Tradition';
import Maps from './components/Maps/Maps';
import Shop from './components/Shop/Shop';
import Pricing from './components/Pricing/Pricing';
import Register from './components/Register/Register';

import CustomIndex from './components/CustomIndex/CustomIndex'; 

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for the custom index page without Header and Footer */}
        <Route path="/" element={<CustomIndex />} />

        {/* Default routes with Header and Footer */}
        <Route
          path="*"
          element={
            <>
              <Header />
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/tradition" element={<Tradition />} />
                <Route path="/maps" element={<Maps />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/register" element={<Register />} />
              </Routes>
              <Footer />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
