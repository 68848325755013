// src/components/Pricing/Pricing.js

import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

export default function Pricing() {
  const sliderSettings = {
    infinite: false,
    speed: 350,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 421,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="space-pb-80 product-detail-page">
      <div className="custom-container">
        
        {/* Welcome Section */}
        <div className="text-center mb-5">
          <h2>WELCOME MATTIES</h2>
          <p>Pick the best plan that meets your PERSONAL FLAG needs.</p>
          <br />
        </div>

        {/* Related Products Section */}
        <div className="related-product mb-20">
          <Slider {...sliderSettings}>
            
            {/* Product Item 1 */}
            <div className="product-boxwrap">
              <div className="product-imgwrap">
                <a href="productno-filter.html">
                  <img className="img-fluid" src="../assets/images/fashion/product/1.jpg" alt="product box" />
                </a>
                <span className="product-discount-label">8%</span>
                <ul className="social">
                  <li><a href="cart.html"><i className="fa fa-shopping-cart"></i></a></li>
                  <li><a href="#"><i className="fa fa-eye"></i></a></li>
                  <li><a href="wishlist.html"><i className="fa fa-heart"></i></a></li>
                  <li><a href="compare.html"><i className="fa fa-refresh"></i></a></li>
                </ul>
              </div>
              <div className="product-detailwrap">
                <a href="shopleft-filter.html">
                  <h5>Cheks red Shirt</h5>
                </a>
                <ul className="rating-list">
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                </ul>
                <div className="pro-price">
                  $180 <span className="old-price">$350</span>
                </div>
              </div>
            </div>

            {/* Product Item 2 */}
            <div className="product-boxwrap">
              <div className="product-imgwrap">
                <a href="productno-filter.html">
                  <img className="img-fluid" src="../assets/images/fashion/product/2.jpg" alt="product box" />
                </a>
                <ul className="social">
                  <li><a href="cart.html"><i className="fa fa-shopping-cart"></i></a></li>
                  <li><a href="#"><i className="fa fa-eye"></i></a></li>
                  <li><a href="wishlist.html"><i className="fa fa-heart"></i></a></li>
                  <li><a href="compare.html"><i className="fa fa-refresh"></i></a></li>
                </ul>
              </div>
              <div className="product-detailwrap">
                <a href="shopleft-filter.html">
                  <h5>Blue Red Jacket</h5>
                </a>
                <ul className="rating-list">
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                </ul>
                <div className="pro-price">
                  $250 <span className="old-price">$329</span>
                </div>
              </div>
            </div>

            {/* Product Item 3 */}
            <div className="product-boxwrap">
              <div className="product-imgwrap">
                <a href="productno-filter.html">
                  <img className="img-fluid" src="../assets/images/fashion/product/3.jpg" alt="product box" />
                </a>
                <span className="product-sale-label">Hot</span>
                <ul className="social">
                  <li><a href="cart.html"><i className="fa fa-shopping-cart"></i></a></li>
                  <li><a href="#"><i className="fa fa-eye"></i></a></li>
                  <li><a href="wishlist.html"><i className="fa fa-heart"></i></a></li>
                  <li><a href="compare.html"><i className="fa fa-refresh"></i></a></li>
                </ul>
              </div>
              <div className="product-detailwrap">
                <a href="shopleft-filter.html">
                  <h5>Rounded Neck T-shirt</h5>
                </a>
                <ul className="rating-list">
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                </ul>
                <div className="pro-price">
                  $320 <span className="old-price">$480</span>
                </div>
              </div>
            </div>

            {/* Product Item 4 */}
            <div className="product-boxwrap">
              <div className="product-imgwrap">
                <a href="productno-filter.html">
                  <img className="img-fluid" src="../assets/images/fashion/product/4.jpg" alt="product box" />
                </a>
                <ul className="social">
                  <li><a href="cart.html"><i className="fa fa-shopping-cart"></i></a></li>
                  <li><a href="#"><i className="fa fa-eye"></i></a></li>
                  <li><a href="wishlist.html"><i className="fa fa-heart"></i></a></li>
                  <li><a href="compare.html"><i className="fa fa-refresh"></i></a></li>
                </ul>
              </div>
              <div className="product-detailwrap">
                <a href="shopleft-filter.html">
                  <h5>Long Red Kurti</h5>
                </a>
                <ul className="rating-list">
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                </ul>
                <div className="pro-price">
                  $480 <span className="old-price">$500</span>
                </div>
              </div>
            </div>

            {/* Product Item 5 */}
            <div className="product-boxwrap">
              <div className="product-imgwrap">
                <a href="productno-filter.html">
                  <img className="img-fluid" src="../assets/images/fashion/product/5.jpg" alt="product box" />
                </a>
                <span className="product-discount-label">15%</span>
                <ul className="social">
                  <li><a href="cart.html"><i className="fa fa-shopping-cart"></i></a></li>
                  <li><a href="#"><i className="fa fa-eye"></i></a></li>
                  <li><a href="wishlist.html"><i className="fa fa-heart"></i></a></li>
                  <li><a href="compare.html"><i className="fa fa-refresh"></i></a></li>
                </ul>
              </div>
              <div className="product-detailwrap">
                <a href="shopleft-filter.html">
                  <h5>Casual Yellow Shirt</h5>
                </a>
                <ul className="rating-list">
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                  <li><i className="fa fa-star"></i></li>
                </ul>
                <div className="pro-price">
                  $150 <span className="old-price">$200</span>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* Note Section */}
        <div className="mt-5">
          <p>Note: 2' x 3' Flag is the standard size, polyester and rectangle shape.</p>
          <p>· Many different shapes and sizes and materials</p>
        </div>
      </div>
    </section>
  );
}
