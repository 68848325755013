// src/components/CustomIndex/CustomIndex.js

import React from 'react';
import { Link } from 'react-router-dom';
import './CustomIndex.css'; // Custom styles

export default function CustomIndex() {
  return (
    <div className="custom-index-page">
      {/* Custom header */}
      <header className="custom-header">
        <div className="logo-container">
          <img src="../assets/images/ijrr/logo-light.png" alt="Jolly Roger Registry" className="jolly-roger-logo" />
        </div>
      </header>

      {/* Parallax section */}
      <section className="custom-parallax" style={{ backgroundImage: 'url(../assets/images/ijrr/index-bg.jpg)' }}>
        <div className="parallax-content">
          <img src="../assets/images/ijrr/IJRR-welcome.png" alt="Welcome Logo" className="welcome-logo" />
          <hr className="divider" />
          <div className="steps-container">
            <p className='golden'>
              <strong className="highlight-red">3</strong> SIMPLE STEPS TO GETTING YOUR PERSONAL FLAG
              <br />
              <strong className="highlight-red">3</strong> INDIVIDUAL PACKAGES TO CREATE YOUR OWN FLAG
              <br />
              <br />
              <strong className='golden'>It's so easy even "Lucky" the Parrot can do it!</strong>
            </p>
            <Link to="/home">
              <img src="../assets/images/ijrr/lucky.png" alt="Lucky the Parrot" className="lucky-parrot" />
            </Link>
            <p><strong className='golden'>Step 1:</strong> <Link to="/home" className='white'>Enter site</Link></p>
            <p><strong className='golden'>Step 2:</strong> Go to Register and enter your name and logo.</p>
            <p>
              <strong className='golden'>Step 3:</strong> Pick out the style, size, and color of your flag and have your personal flag printed with your logo.
              <br />
              <strong className="golden">Note: </strong> <span> Logos are available on the International Jolly Roger Registry’s website.</span>
            </p>
          </div>
        </div>
      </section>

      {/* Custom footer */}
      <footer className="custom-footer">
        <p>© 2024 Jolly Roger Registry</p>
      </footer>
    </div>
  );
}
