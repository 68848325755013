// src/components/Shop/ProductDetail/UploadTab/UploadTab.js

import React, { useRef } from 'react';
import './UploadTab.css'; // Assuming the styles are moved to this CSS file

export default function UploadTab({ onImageUploaded }) {
  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        onImageUploaded(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleFileUpload({
      target: {
        files: event.dataTransfer.files,
      },
    });
  };

  return (
    <div className="upload-container">
      <h5>Upload your own images or logos, high resolution artwork will look the best.</h5>
      <div
        className="upload-area"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: 'none' }}
          accept="image/*"
        />
        <i className="fa fa-cloud-upload"></i>
        <p>
          Drag & Drop or{' '}
          <button className="btn btn-primary" onClick={triggerFileInput}>
            Browse Your Computer
          </button>
        </p>
        <small>JPG, PNG, EPS, AI and PDF (Max 5 MB)</small>
      </div>
      <div className="file-type-info">
        <p>
          Do you have a file type not listed above?{' '}
          <a href="mailto:contact@example.com">Email it to us</a> or upload it and
          we'll review it and have someone reach out to you before we produce your
          order!
        </p>
      </div>
    </div>
  );
}
