// src/components/Shop/Categories/Categories.js

import React, { useState, useEffect } from 'react';
import './Categories.css';

export default function Categories({ onModelSelect }) {
  const [categories, setCategories] = useState([]);
  const [currentTab, setCurrentTab] = useState('tab-1');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/public-inventory`);
        const data = await response.json();
        setCategories(data.categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const changeTab = (tabId) => {
    setCurrentTab(tabId);
  };

  const selectModel = (model) => {
    console.log("Model selected:", model.image); // Log the selected model's image
    onModelSelect(model.image); // Pass the selected model's image URL up to Shop
  };

  return (
    <section className="product-detail-page categories-space">
      <div className="custom-container">
        <div className="row">
          <div className="col-sm-12">
            <div className="product-card product-detail-tab">
              <ul className="nav nav-tabs">
                {categories.map((category, index) => (
                  <li key={category.id}>
                    <a
                      className={`btn ${currentTab === `tab-${index + 1}` ? 'active' : ''}`}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        changeTab(`tab-${index + 1}`);
                      }}
                    >
                      {category.name}
                    </a>
                  </li>
                ))}
              </ul>

              <div className="tab-content">
                {categories.map((category, index) => (
                  <div
                    key={category.id}
                    id={`tab-${index + 1}`}
                    className={`tab-pane fade ${currentTab === `tab-${index + 1}` ? 'active show' : ''}`}
                  >
                    <div className="custom-container">
                      <div className="row">
                        <div className="col-12">
                          <div className="categoryslide-8 left-slider arrow-hide">
                            {category.models.map((model) => (
                              <div key={model.name} className="category-grid">
                                <div className="img-wrap">
                                  <a
                                    href="#"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => selectModel(model)}
                                  >
                                    <img className="img-fluid" src={model.image} alt={model.name} />
                                  </a>
                                </div>
                                <div className="detail-wrap">
                                  <a
                                    href="#"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => selectModel(model)}
                                  >
                                    <h4>{model.name}</h4>
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
