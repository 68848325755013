// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBKF5B45kZfV-xee0YE5s6A-ekeHdtGFXE",
  authDomain: "jolly-roger-60aa4.firebaseapp.com",
  projectId: "jolly-roger-60aa4",
  storageBucket: "jolly-roger-60aa4.appspot.com",
  messagingSenderId: "509465678555",
  appId: "1:509465678555:web:906080f0b2ff9cbef3f2fe",
  measurementId: "G-WZ91WV83V9",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };
