// src/layout/Header/Header.js

import React from 'react'
import './Header.css';

export default function Header() {
  return (
    <div>
      <header className="cdx-header">
        
        <div className="custom-container">
          <div className="row">
            <div className="col-12">
              <div className="header-contian">
                <div className="header-left">
                  <div className="brand-logo">
                    <a href="/">
                        <img className="img-fluid" src="../assets/images/ijrr/vertical-logo.png" alt="IJRR" />
                      </a>
                    </div>
                </div>
                <div className="header-right">
                    <ul className="codex-menu">
                      <li className="close-menu"><i className="icofont-close"></i>close menu</li>
                      <li className="menu-item"><a href="/home">Home</a></li>
                      <li className="menu-item"><a href="/about">About</a></li>
                      <li className="menu-item"><a href="/tradition">Tradition</a></li>
                      <li className="menu-item"><a href="/maps">Maps</a></li>
                      <li className="menu-item"><a href="/shop">Shop</a></li>
                      <li className="menu-item"><a href="/pricing">Pricing</a></li>
                      <li className="menu-item"><a href="/register">Register</a></li>
                      {/* <li className="menu-item"><a href="#">components<i className="icofont-simple-down"></i></a>
                        <ul className="submenu-list">
                          <li><a href="#">button</a></li>
                        </ul>
                      </li> */}
                    </ul>
                </div>
                <div className="nav-iconlist">
                  <ul>
                    <li><a href="#"> 
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 22C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20C8.44772 20 8 20.4477 8 21C8 21.5523 8.44772 22 9 22Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                          <path d="M20 22C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                          <path d="M1 1H5L7.68 14.39C7.77144 14.8504 8.02191 15.264 8.38755 15.5583C8.75318 15.8526 9.2107 16.009 9.68 16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                        <div className="nav-notification">2</div></a></li>
                    <li><a href="#">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.4201 4.57996C19.9184 4.07653 19.3223 3.67709 18.6659 3.40455C18.0095 3.132 17.3058 2.9917 16.5951 2.9917C15.8844 2.9917 15.1806 3.132 14.5243 3.40455C13.8679 3.67709 13.2718 4.07653 12.7701 4.57996L12.0001 5.35996L11.2301 4.57996C10.7284 4.07653 10.1323 3.67709 9.47591 3.40455C8.81953 3.132 8.1158 2.9917 7.40509 2.9917C6.69437 2.9917 5.99065 3.132 5.33427 3.40455C4.67789 3.67709 4.08176 4.07653 3.58009 4.57996C1.46009 6.69996 1.33009 10.28 4.00009 13L12.0001 21L20.0001 13C22.6701 10.28 22.5401 6.69996 20.4201 4.57996Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                        <div className="nav-notification">2</div></a></li>
                    <li className="dropdownmenu"><a className="dropdown-action" href="#">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                          <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg></a>
                      <ul className="dropdownitem-list">
                        <li><a href="#">login</a></li>
                        <li><a href="#">register</a></li>
                      </ul>
                    </li>
                  </ul>
                  <div className="mobilemenu-toggle"><i className="icofont-navigation-menu"></i></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="search-bar">  
        <div className="input-group">
          <div className="input-group-text"><i className="ti-search"></i></div>
          <input className="form-control" type="text" placeholder="Finde Your product" />
        </div>
        <div className="clsoe-search"><i className="ti-close"></i></div>
      </div>
    </div>
  )
}
