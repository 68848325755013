// src/components/HomeSlider/HomeSlider.js

import React, { useRef } from "react";
import './HomeSlider.css';
import HomeSignUp from '../HomeSignUp/HomeSignUp'; // Import the HomeSignUp component

export default function HomeSlider() {

  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <section className="home-space">
      <div className="homearrowlide-1 arrow-style1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div>
          <div className="fashion-slider">
            {/* Background Video */}
            <video
              ref={videoRef}
              className="video-background"
              muted
              playsInline
              poster="/assets/videos/intro-video/intro-video-lg.jpg"
            >
              <source src="/assets/videos/intro-video/intro-video-lg.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <div className="custom-container">
              <div className="row">
                <div className="col-12">
                  {/*<div className="fashion-contain">
                    <div>
                      <h5>Welcome to Jolly Roger</h5>
                      <h2>Create Your Pirate Flag</h2>
                      <p className="text-dark">
                        Join IJRR and design your unique flag. It's simple and fun!
                      </p>
                      <a className="btn btn-primary" href="/shop">
                        Get Started
                      </a>
                    </div>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
